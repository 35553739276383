import { useEffect, useState } from 'react';

export const useExternalScripts = (mods) => {
  const [loading, setLoading] = useState(true);
  const [loadedScripts, setLoadedScripts] = useState(0);
  const [loadedModsWithData, setLoadedModsWithData] = useState([]);

  useEffect(() => {
    const handleScript = (e) => {
      if (e.type === 'load') {
        setLoadedScripts((l) => l + 1);
      }

      // If a mod fails to load, we should still mark it as a loaded script so we can continue with error handling
      if (e.type === 'error') {
        console.error(`Failed to load ${e.target.src}`);
        setLoadedScripts((l) => l + 1);
      }
    };

    if (!mods) return;

    const modsWithWeb = mods.filter((mod) => mod.web);

    for (const url of modsWithWeb.map((mod) => mod.web.bundle)) {
      const script = document.querySelector(`script[src="${url}"]`);

      if (!script) {
        const element = document.createElement('script');
        element.type = 'text/javascript';
        element.src = url;
        element.async = true;
        document.body.appendChild(element);
        element.addEventListener('load', handleScript);
        element.addEventListener('error', handleScript);
      }
    }
  }, [mods]);

  useEffect(() => {
    if (!mods) return;
    const modsWithWeb = mods.filter((mod) => mod.web);

    const isEverythingAlreadyLoaded = loadedScripts === modsWithWeb.length;
    if (isEverythingAlreadyLoaded) {
      const loadedMods = modsWithWeb
        .filter((mod) => window[mod.name])
        .map((mod) => {
          return { ...mod, external: window[mod.name] };
        });
      setLoadedModsWithData(loadedMods);
      setLoading(false);
    }
  }, [mods, loadedScripts]);

  if (loading) {
    return { loading, loadedScripts };
  }

  return {
    loading,
    webMods: loadedModsWithData,
  };
};
