import styled from 'styled-components/macro';
import sdtdLogo from '../../img/7d2d-logo.png';
import sdtdLogoSquare from '../../img/7d2d-square.webp';
import { AuthLink } from './authLink';
import { NavLink } from '../NavLink';
import { HideBasedOnAuth } from '../auth/hideBasedOnAuth';
import PropTypes from 'prop-types';
import { PERMISSIONS } from '../auth';
import { FaArrowLeft, FaArrowRight, FaMap, FaCogs, FaFlask, FaTerminal, FaUser } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { useMods } from '../../lib/mods';
import { useAuth } from '../auth/index';

const Container = styled.div`
  height: 100vh;
  width: ${(props) => (props.collapsed ? '100px' : '250px')};
  position: relative;
  font-size: 2rem;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledUl = styled.ul`
  list-style-type: none;

  padding-left: 1rem;
  position: relative;
`;

const StyledLi = styled.li`
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${(props) => (props.isActive ? 'red' : 'white')};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > * {
    margin: 0 0.5rem;
  }
`;

const ImageContainer = styled.div`
  width: ${(props) => (props.collapsed ? '100px' : '250px')};
  height: 200px;
  top: 0;
`;

const SdtdLogoContainer = styled.img`
  width: 100%;
  height: ${(props) => (props.collapsed ? '50%' : '100%')};
`;

const StyledCollapse = styled.div`
  width: 100%;
  height: 50px;
  cursor: pointer;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: ${(props) => (props.isActive ? 'red' : 'white')};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > * {
    margin: 0 0.5rem;
  }
`;

function SdtdLogo(props) {
  return (
    <ImageContainer collapsed={props.collapsed}>
      <SdtdLogoContainer collapsed={props.collapsed} src={props.collapsed ? sdtdLogoSquare : sdtdLogo} alt="Image" />
    </ImageContainer>
  );
}

SdtdLogo.propTypes = {
  collapsed: PropTypes.bool.isRequired,
};

export function SideBarLi({ children }) {
  return (
    <StyledLi>
      <nav>{children}</nav>
    </StyledLi>
  );
}

SideBarLi.propTypes = {
  children: PropTypes.node.isRequired,
};

export function SidebarNavLink({ icon, title, component, to, collapsed, external = false }) {
  const location = useLocation();
  const isActive = location.pathname === to || (location.pathname.startsWith('/mods/') && location.pathname.endsWith(to));

  const content = title ? title : component;

  if (external) {
    return (
      <ExternalLink isActive={isActive} href={to}>
        {icon}
        {collapsed ? '' : content}
      </ExternalLink>
    );
  }

  return (
    <StyledNavLink to={to} isActive={isActive}>
      {icon}
      {collapsed ? '' : content}
    </StyledNavLink>
  );
}

SidebarNavLink.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string,
  component: PropTypes.node,
  to: PropTypes.string.isRequired,
  collapsed: PropTypes.bool.isRequired,
  external: PropTypes.bool,
};

export function SideBar({ collapsed, setCollapsed }) {
  function onClickCollapse() {
    setCollapsed(!collapsed);
  }
  const { status } = useAuth();
  const { modRoutes } = useMods();

  return (
    <Container collapsed={collapsed}>
      <SdtdLogo collapsed={collapsed} />
      <StyledUl>
        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['web.map'], method: 'GET' }}>
          <SideBarLi>
            <SidebarNavLink collapsed={collapsed} icon={<FaMap />} title={'Map'} to="/map" />
          </SideBarLi>
        </HideBasedOnAuth>

        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.Command'], method: 'GET' }}>
          <SideBarLi>
            <SidebarNavLink collapsed={collapsed} icon={<FaTerminal />} title={'Console'} to="/console" />
          </SideBarLi>
        </HideBasedOnAuth>

        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.Command'], method: 'GET' }}>
          <SideBarLi>
            <SidebarNavLink collapsed={collapsed} icon={<FaCogs />} title={'Settings'} to="/settings" />
          </SideBarLi>
        </HideBasedOnAuth>

        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.Mods'], method: 'GET' }}>
          <SideBarLi>
            <SidebarNavLink collapsed={collapsed} icon={<FaFlask />} title={'Mods'} to="/mods" />
          </SideBarLi>
        </HideBasedOnAuth>

        {modRoutes.map((route) => {
          return (
            <SideBarLi key={route.path}>
              <SidebarNavLink collapsed={collapsed} icon={<FaFlask />} title={route.name} to={route.path} />
            </SideBarLi>
          );
        })}
      </StyledUl>

      <StyledUl>
        {status.loggedIn && (
          <SideBarLi>
            <SidebarNavLink collapsed={collapsed} icon={<FaUser />} title={`${status.username}`} to="/profile" />
          </SideBarLi>
        )}

        <SideBarLi>
          <AuthLink collapsed={collapsed} />
        </SideBarLi>
      </StyledUl>

      <StyledCollapse onClick={onClickCollapse}>{collapsed ? <FaArrowRight /> : <FaArrowLeft />}</StyledCollapse>
    </Container>
  );
}

SideBar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};
