import React from 'react';
import { useMods } from '../lib/mods';
import styled from 'styled-components/macro';
import { FaLink } from 'react-icons/fa';
import PropTypes from 'prop-types';

const ModsContainer = styled.div`
  flex: 1;
  flex-direction: column;
  text-align: left;
`;

const StyledLinkIcon = styled(FaLink)`
  cursor: pointer;
  margin: 0 1rem;
  &:hover {
    color: ${(props) => props.theme.colors.tfpRed};
  }
`;

function ModLink({ url }) {
  if (!url) return null;

  return <StyledLinkIcon onClick={() => window.open(url, '_blank')} />;
}

ModLink.propTypes = {
  url: PropTypes.string.isRequired,
};

export function Mods() {
  const { mods } = useMods();

  if (!mods) {
    return <p>Mods still loading...</p>;
  }

  const modsList = mods
    .filter((mod) => mod.displayName)
    .map((mod) => {
      return (
        <div key={mod.name}>
          <h2>
            {mod.displayName}
            <ModLink url={mod.website} />
          </h2>
          <small>
            Author: {mod.author}, version: v{mod.version}
          </small>
          <p>{mod.description}</p>
        </div>
      );
    });

  return (
    <ModsContainer>
      <h1>Mods</h1>
      {modsList}
    </ModsContainer>
  );
}
