import { LayerGroup, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { HTTP } from '../../lib/http';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import animalIcon from '../../img/map/icons/ui_game_symbol_animal_tracker.png';
import rabbitIcon from '../../img/map/icons/ui_game_symbol_tracking_rabbit.png';
import bearIcon from '../../img/map/icons/ui_game_symbol_tracking_bear.png';
import chickenIcon from '../../img/map/icons/ui_game_symbol_tracking_chicken.png';
import deerIcon from '../../img/map/icons/ui_game_symbol_tracking_deer.png';
import mountainLionIcon from '../../img/map/icons/ui_game_symbol_tracking_mountain_lion.png';
import pigIcon from '../../img/map/icons/ui_game_symbol_tracking_pig.png';
import snakeIcon from '../../img/map/icons/ui_game_symbol_tracking_snake.png';
import wolfIcon from '../../img/map/icons/ui_game_symbol_tracking_wolf.png';

function getIcon(animalName) {
  function getIconElement(iconUrl) {
    return L.icon({
      iconUrl,
      iconSize: [32, 32],
    });
  }

  switch (animalName) {
    case 'animalRabbit':
      return getIconElement(rabbitIcon);
    case 'animalBear':
      return getIconElement(bearIcon);
    case 'animalChicken':
      return getIconElement(chickenIcon);
    case 'animalDeer':
      return getIconElement(deerIcon);
    case 'animalMountainLion':
      return getIconElement(mountainLionIcon);
    case 'animalPig':
      return getIconElement(pigIcon);
    case 'animalSnake':
      return getIconElement(snakeIcon);
    case 'animalWolf':
      return getIconElement(wolfIcon);
    default:
      return getIconElement(animalIcon);
  }
}

function Animal({ lat, lng, name }) {
  return (
    <Marker position={[lat, lng]} icon={getIcon(name)}>
      <Popup>{name}</Popup>
    </Marker>
  );
}

Animal.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export function AnimalLayer() {
  const [animals, setAnimals] = useState([]);

  async function loadAnimals() {
    const animals = await HTTP.getAnimals();
    setAnimals(animals);
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      loadAnimals();
    }, 10000);

    loadAnimals();
    return () => clearInterval(interval);
  }, []);

  const allMarkers = animals.map((a) => <Animal key={a.id} lat={a.position.x} lng={a.position.z} name={a.name}></Animal>);

  return <LayerGroup name={'animals'}>{allMarkers}</LayerGroup>;
}
