import { useMap, Rectangle, Tooltip, LayerGroup } from 'react-leaflet';
import L from 'leaflet';
import PropTypes from 'prop-types';

function CoordToRegion(latlng, mapInfo) {
  const x = Math.floor((latlng.lat + 16777216) / mapInfo.regionSize - 16777216 / mapInfo.regionSize);
  const y = Math.floor((latlng.lng + 16777216) / mapInfo.regionSize - 16777216 / mapInfo.regionSize) - 1;
  return L.latLng(x, y);
}

function formatRegionFileName(latlng) {
  return `r.${latlng.lat}.${latlng.lng}.7rg`;
}

export function RegionsLayer({ mapInfo }) {
  const map = useMap();

  const mapBounds = map.getBounds();
  const mapMin = mapBounds.getSouthWest();
  const mapMax = mapBounds.getNorthEast();

  const tileSize = mapInfo.regionSize;

  const startChunkX = Math.floor(mapMin.lng / tileSize);
  const startChunkY = Math.floor(mapMin.lat / tileSize);
  const endChunkX = Math.ceil(mapMax.lng / tileSize);
  const endChunkY = Math.ceil(mapMax.lat / tileSize);

  const rectangles = [];

  for (let chunkX = startChunkX; chunkX <= endChunkX; chunkX++) {
    for (let chunkY = startChunkY; chunkY <= endChunkY; chunkY++) {
      const rectMin = L.point(chunkX * tileSize, chunkY * tileSize);
      const rectMax = L.point((chunkX + 1) * tileSize, (chunkY + 1) * tileSize);

      const boundsMin = map.unproject(rectMin, mapInfo.maxZoom);
      const boundsMax = map.unproject(rectMax, mapInfo.maxZoom);

      if (boundsMax.lng >= mapMin.lng && boundsMin.lng <= mapMax.lng && boundsMax.lat >= mapMin.lat && boundsMin.lat <= mapMax.lat) {
        rectangles.push(
          <Rectangle bounds={[boundsMin, boundsMax]} key={`${chunkX}-${chunkY}`} color="gray" stroke={true} fill={true} weight={1}>
            <Tooltip>{formatRegionFileName(CoordToRegion(boundsMin, mapInfo))}</Tooltip>
          </Rectangle>
        );
      }
    }
  }

  return <LayerGroup name={'regions'}>{rectangles}</LayerGroup>;
}

RegionsLayer.propTypes = {
  mapInfo: PropTypes.object.isRequired,
};
