import axios, { AxiosError } from 'axios';
import { sendErrorToast } from './toast';

const client = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.error(error);
    if (error.response.status === 403) {
      sendErrorToast('Authentication error');
    } else if (error.response.data && error.response.data.meta && error.response.data.meta.errorCode) {
      sendErrorToast(error.response.data.meta.errorCode);
    } else {
      sendErrorToast('Unknown HTTP error');
    }

    return Promise.reject(error);
  }
);

export class HTTP {
  static async get(url) {
    const response = await client.get(url);
    return response.data.data;
  }

  static async post(url, data) {
    const response = await client.post(url, data);
    return response.data.data;
  }

  static async delete(url, data) {
    const response = await client.delete(url, data);
    return response.data.data;
  }

  static async put(url, data) {
    const response = await client.put(url, data);
    return response.data.data;
  }

  static async executeCommand(command) {
    const response = await client.post(`/api/command`, { command });
    return response.data.data;
  }

  static async getMods() {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('Not production mode, returning hardcoded mods');
      return [
        {
          name: 'TFP_MarkersExample',
          displayName: 'Markers (Example Web Mod)',
          description: 'Allows placing custom markers on the web map',
          author: 'Catalysm and Alloc',
          version: '21.0.258.0',
          website: 'http://markers-mod.local',
          web: {
            bundle: 'http://localhost:52000/mods/markers/build/WebMod/bundle.js',
          },
        },
        {
          name: 'Inspirational Web Quotes',
          displayName: 'Inspirational Web Quotes',
          description: 'Get inspired!',
          author: 'Catalysm and Alloc',
          version: '21.0.258.0',
          website: 'http://quotes-mod.local',
          web: {
            bundle: 'http://localhost:52000/mods/inspirational-quotes/build/WebMod/bundle.js',
          },
        },
      ];
    }

    const response = await client.get('/api/mods');
    return response.data.data;
  }

  static async getAnimals() {
    const response = await client.get('/api/animal');
    return response.data.data;
  }

  static async getHostiles() {
    const response = await client.get('/api/hostile');
    return response.data.data;
  }

  static async getAdminList() {
    const raw = await this.executeCommand('admin list');
    return raw.result
      .split('\n')
      .slice(2, -3)
      .map((s) => {
        const trimmed = s.trim();
        const {
          groups: { level, ID, name },
        } = /(?<level>\d+): (?<ID>\w+) \(.*stored name: (?<name>(\w+)|)\)/g.exec(trimmed);

        return {
          level,
          ID,
          name,
        };
      });
  }

  static async getCommandPermissions() {
    const raw = await this.executeCommand('commandpermission list');
    const parsed = raw.result
      .split('\n')
      .slice(2)
      .map((s) => s.trim())
      .filter(Boolean)
      .map((s) => {
        const {
          groups: { level, command },
        } = /(?<level>\d+): (?<command>.+)/g.exec(s);
        return { level, command };
      });

    return parsed;
  }

  static async getWebPermissions() {
    const raw = await this.executeCommand('webpermission list');
    const parsed = raw.result
      .split('\n')
      .slice(2)
      .map((s) => s.trim())
      .filter(Boolean)
      .map((s) => {
        const {
          groups: { level, permission },
        } = /(?<level>\d+): (?<permission>.+)/g.exec(s);
        return { level, permission };
      });

    return parsed;
  }

  static async getWebTokens() {
    const raw = await this.executeCommand('webtokens list');
    const parsed = raw.result
      .split('\n')
      .slice(2)
      .map((s) => s.trim())
      .filter(Boolean)
      .map((s) => {
        const {
          groups: { level, name, token },
        } = /(?<level>\d+): (?<name>.+) \/ (?<token>.+)/g.exec(s);
        return { level, token, name };
      });

    return parsed;
  }

  static async getBans() {
    const raw = await this.executeCommand('ban list');
    const parsed = raw.result
      .split('\n')
      .slice(2)
      .map((s) => s.trim())
      .filter(Boolean)
      .map((s) => {
        const {
          groups: { date, ID, name, reason },
        } = /(?<date>.+) - (?<ID>.+) (?<name>.+) -(?<reason>.*)/g.exec(s);
        return { date: new Date(date), ID, name, reason };
      });

    return parsed;
  }

  static async getLatestLogLines(count) {
    const response = await client.get(`/api/log?count=${count}`);
    return response.data.data;
  }

  static async login(username, password) {
    const response = await client.post('/session/login', { username, password });
    return response.data.data;
  }

  static async registerUser(username, password, token) {
    try {
      await client.post('/api/registeruser', { username, password, token });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response.status > 400 && error.response.status < 500) {
          throw new Error(error.response.data.meta.errorCode);
        }
      }
      throw new Error('Internal server error');
    }

    return;
  }

  static async getPlayers() {
    const response = await client.get('/api/player');
    return response.data.data.players;
  }

  static async getMapConfig() {
    const response = await client.get('/api/map/config');
    return response.data.data;
  }

  static async getItems() {
    const response = await client.get('/api/item');
    return response.data.data;
  }
}
